<template>
  <div class="card">
    <div class="card-body">
      <h3 class="typed">Ausweisnummer finden</h3>

      <div class="row">
        <div class="col">
          <p>
            Was ist die <b>Lernfahrausweis Registriernummer</b>? Das ist die <b><span class="red">rot</span></b>
            markierte
            Zahl, die je nach kanton an einem anderen Ort zu finden ist. Siehe unten nach, wo die Registriernummer für
            den Kanton
            Solothurn, den Kanton Aargau oder den Kanton Basel-Land steht.
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 col-sm-12 col-12 center">
          <h3 class="typed">Kanton Solothurn</h3>
          <p class="center">
            Die Registriernummer steht im Feld "Reg-Nr.".
          </p>
          <a href="/static/lernfahrausweis_marked_solothurn.jpg">
            <img class="permit" src="/static/lernfahrausweis_marked_solothurn.jpg" alt="Lernfahrausweis Solothurn" />
          </a>
        </div>
        <div class="col-md-4 col-sm-12 col-12 center">
          <h3 class="typed">Kanton Aargau</h3>
          <p class="center">
            Die Registriernummer steht im Adressfeld unten rechts.
          </p>
          <a href="/static/lernfahrausweis_marked_aargau.jpg">
            <img class="permit" src="/static/lernfahrausweis_marked_aargau.jpg" alt="Lernfahrausweis Aargau" />
          </a>
        </div>
        <div class="col-md-4 col-sm-12 col-12 center">
          <h3 class="typed">Kanton Basel-Land</h3>
          <p class="center">
            Die Registriernummer steht im Feld "Pin".
          </p>
          <a href="/static/lernfahrausweis_marked_bl.jpg">
            <img class="permit" src="/static/lernfahrausweis_marked_bl.jpg" alt="Lernfahrausweis Basel-Land" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LicenseInfo'
};
</script>

<style lang="scss" scoped>
img.permit {
  height: 500px;
  max-width: 100%;
}
</style>
